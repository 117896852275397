import React from 'react'

import {Badge} from '@pleo-io/telescope'

import {statusToBadgeText} from '../docs'

export const Status = ({status}: {status: string}) => {
    if (status === 'new') {
        return (
            <Badge variant="discover" loud>
                {statusToBadgeText['new']}
            </Badge>
        )
    } else if (status === 'draft') {
        return (
            <Badge variant="warning" loud>
                {statusToBadgeText['draft']}
            </Badge>
        )
    } else if (status === 'lab') {
        return (
            <Badge variant="warning" loud>
                {statusToBadgeText['lab']}
            </Badge>
        )
    }

    return null
}
